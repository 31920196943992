.ansattSelveBilde {
  height: 150px;
}
.stillingUtfyllendeTekst {
  margin-top: -16px;
  font-size: 17px;
  opacity: 0.8;
}

.carousel .slide {
  background-color: white;
}
