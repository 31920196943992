@import url(https://fonts.googleapis.com/css?family=Roboto:400|Poppins:400);
@import url(https://fonts.googleapis.com/css?family=Roboto:400|Poppins:400);
body {
  margin: 0px;
  width: 100vw;
  height: 100vh;
}

#root {
  height: 100%;
}

html {
  font-size: 118.8%;
} /*19px*/

body {
  background-color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 1.65;
  color: #333;
}

p {
  margin-bottom: 1.15rem;
  font-size: 19px;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 2.75rem 0 1.05rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  line-height: 1.15;
}

h1 {
  margin-top: 0;
  font-size: 4.209em;
}

h2 {
  font-size: 3.157em;
}

h3 {
  font-size: 2.369em;
}

h4 {
  font-size: 1.777em;
}

h5 {
  font-size: 1.333em;
}

small,
.text_small {
  font-size: 0.75em;
}

/* @media only screen and (min-width: 600px) {
  .Bakgrunn {
    background-image: url(svg/Bakgrunn_ElSpark.svg);
    background-repeat: no-repeat;
    background-size: cover;
  }
} */

/* @media only screen and (min-width: 992px) {
  .header {
    height: 80vh;
    position: relative;
  }
} */
.btnbig {
  width: 400px;
  height: 400px;
}

.headline {
  margin: 0;
  position: absolute;
  top: 27%;
  left: 40%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: black;
}

.ButtonStart {
  position: absolute;
  top: 50vh;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  text-align: center;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
}

@media only screen and (max-width: 600px) {
  .ButtonStart {
    position: absolute;
    top: 70vh;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    text-align: center;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
  }
}

@media only screen and (min-width: 768px) {
  .HowItWorks {
    position: relative;
    top: 42%;
  }
  .howItWorksSmall {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .howItWorksBig {
    display: none;
  }
}

.ansattSelveBilde {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

.Bakgrunn {
  background-image: url(/static/media/BakgrunnElspark2.61c26b60.svg);
  background-repeat: no-repeat;
  height: 70vh;
  background-size: cover;
  width: 100%;
  /* -webkit-filter: grayscale(0.3); /* Google Chrome, Safari 6+ & Opera 15+ */
  /* filter: grayscale(0.3); Microsoft Edge and Firefox 35+ */
}

.BakgrunnVenstre {
  background-image: url(/static/media/venstre.cb3d56ff.svg);
  height: 500px;
  position: relative;
}
.BakgrunnHoyre {
  background-image: url(/static/media/hoyre.b59c3618.svg);
  height: 500px;
  position: relative;
}

body {
  margin: 0px;
  width: 100vw;
  height: 100vh;
}

#root {
  height: 100%;
}

.map {
  height: 90vh;
  /* -webkit-filter: grayscale(100%); Safari 6.0 - 9.0 */
  /* filter: grayscale(300%) invert(100%) sepia(10%) brightness(100%); */
}

.leaflet-popup {
  position: absolute;
  text-align: center;
  margin-bottom: 39px;
}

.btn {
  width: 300px;
  height: 150px;
  border-radius: 50%;
  position: absolute;
}

.btn-warning {
  color: #000000;
  background-color: #ffffff;
  border-color: #f4dd02;
  box-shadow: -7px -1px 20px 1px black;
  border-radius: 50%;
}

.btn-danger {
  color: #fff;
  background-color: #7d7d7d;
  border-color: #dc3545;
}

.btn-info {
  color: #000;
  background-color: #ffffff;
  border-color: #000000;
  font-size: x-large;
  box-shadow: -7px -1px 20px 1px black;
}

video {
  width: 100%;
}

.container {
  max-width: 600px;
}

.header {
  height: 500px;
  position: relative;
}

.message-form {
  z-index: 999;
  /* position: absolute; */

  max-width: 600px;
}

.message-form {
  border-radius: 50%;
}

.MessageFormDiv {
  margin: auto;
  width: 60%;
  padding: 10px;
}

.overskriftform1 {
  font-style: bold;
  font-size: 24px;
}

.overskriftform2 {
  font-style: italic;
  font-size: 20px;
}

.thanks-form {
  z-index: 999;
  position: absolute;
  top: 5px;
  right: 5px;
  max-width: 220px;
  text-align: center;
  max-width: 220px;
  margin: auto;
  width: 60%;
}

.footer {
  z-index: 999;
  position: absolute;
  bottom: 35px;
  left: 5px;
  padding: 5px;
}

.loadingelspark {
  border-radius: 50%;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: #000;
  color: #fff;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

.CarouselDiv {
  box-shadow: 3px 3px 20px 8px black;
  background-color: '#1a2b1b';
  height: '100px';
  width: '100%';
  text-align: center;
}

.carousel-slide {
  box-shadow: -1px 6px 20px 1px black;
}

.ansattSelveBilde {
  height: 150px;
}
.stillingUtfyllendeTekst {
  margin-top: -16px;
  font-size: 17px;
  opacity: 0.8;
}

.carousel .slide {
  background-color: white;
}

body {
  margin: 0px;
  width: 100vw;
  height: 100vh;
}

#root {
  height: 100%;
}

html {
  font-size: 100%;
} /*16px*/

body {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 1.65;
  color: #333;
  margin: 0px;
  width: 100vw;
  height: 100vh;
}

p {
  margin-bottom: 1.15rem;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 2.75rem 0 1.05rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  line-height: 1.15;
}

h1 {
  margin-top: 0;
  font-size: 4.209em;
}

h2 {
  font-size: 3.157em;
}

h3 {
  font-size: 2.369em;
}

h4 {
  font-size: 1.777em;
}

h5 {
  font-size: 1.333em;
}

small,
.text_small {
  font-size: 0.75em;
}

/* .values {
  background-color: #bbb;
  border-radius: 20%;
  display: inline-block;
} */

