.CarouselDiv {
  box-shadow: 3px 3px 20px 8px black;
  background-color: '#1a2b1b';
  height: '100px';
  width: '100%';
  text-align: center;
}

.carousel-slide {
  box-shadow: -1px 6px 20px 1px black;
}
