body {
  margin: 0px;
  width: 100vw;
  height: 100vh;
}

#root {
  height: 100%;
}

@import url('https://fonts.googleapis.com/css?family=Roboto:400|Poppins:400');

html {
  font-size: 118.8%;
} /*19px*/

body {
  background-color: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  line-height: 1.65;
  color: #333;
}

p {
  margin-bottom: 1.15rem;
  font-size: 19px;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 2.75rem 0 1.05rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  line-height: 1.15;
}

h1 {
  margin-top: 0;
  font-size: 4.209em;
}

h2 {
  font-size: 3.157em;
}

h3 {
  font-size: 2.369em;
}

h4 {
  font-size: 1.777em;
}

h5 {
  font-size: 1.333em;
}

small,
.text_small {
  font-size: 0.75em;
}
